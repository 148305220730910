<template>
  <transition name="fade" v-if="show">
    <section class="screen-modal" :class="{ [this.type]: this.type }">
      <div class="modal-backdrop"></div>
      <div class="modal-content">
        <header class="modal-header">
          <!-- TODO srediti i ovu ikonicu kasnije da koristi nove ikonice -->
          <svg-icon :icon="icon"></svg-icon>
        </header>
        <section class="modal-inner-content">
          <slot></slot>
        </section>
        <footer class="modal-footer">
          <button class="modal-btn" @click="closeModal">
            {{ cancelButtonLabel }}
          </button>
          <button class="modal-btn" @click="confirm">
            {{ confirmButtonLabel }} {{ !this.hideTimer ? `(${timer})` : "" }}
          </button>
        </footer>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: "ScreenModalInner",
  data() {
    return {
      interval: null,
      timer: 5,
      icon: this.type === "success" ? "checkmark" : "x-cross"
    };
  },
  mounted() {
    if (!this.hideTimer) {
      this.interval = setInterval(() => {
        this.timer--;
      }, 1000);
    }
  },
  watch: {
    timer() {
      if (this.timer === 0) {
        this.confirm();
      }
    }
  },
  beforeUnmount() {
    if (!this.hideTimer) this.resetDataAndInterval();
    this.closeModal();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirm() {
      this.confirmAction();
      if (!this.preventConfirm) {
        setTimeout(() => {
          this.closeModal();
        });
      }
    },
    resetDataAndInterval() {
      this.timer = 5;
    }
  },
  props: {
    confirmAction: {
      required: true,
      type: Function
    },
    show: {
      required: false,
      type: Boolean
    },
    type: {
      required: true,
      type: String
    },
    confirmButtonLabel: {
      type: String,
      required: true
    },
    cancelButtonLabel: {
      type: String,
      required: true
    },
    hideTimer: {
      type: Boolean,
      required: false
    },
    preventConfirm: {
      type: Boolean,
      required: false
    }
  }
};
</script>
